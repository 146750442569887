<template>
    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                <a-row class="yj-form">
                    <a-col :span="20">
                        <a-form layout="horizontal" style="padding:10px  20px 0px;height:auto;">
                            <a-form-item label="" style="margin-bottom:10px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout='inline'>
                                    <a-form-item label="状态">
                                        <a-radio-group default-value="PubState" v-model="PubState" @change="getTableData">
                                            <a-radio-button value="-1">全部</a-radio-button>
                                            <a-radio-button value="0">未发布</a-radio-button>
                                            <a-radio-button value="1">已发布</a-radio-button>
                                            <a-radio-button value="2">已结束</a-radio-button>
                                        </a-radio-group>
                                    </a-form-item>
                                </a-form>
                            </a-form-item>
                            <a-form-item label="" style="margin-bottom:0px;"
                                         :label-col="formItemLayout.labelCol"
                                         :wrapper-col="formItemLayout.wrapperCol">
                                <a-form layout='inline'>
                                    <a-form-item label="活动">
                                        <a-input placeholder="输入活动名称" v-model="name" :value="name" style="width:278px;" />
                                    </a-form-item>
                                    <a-form-item label="">
                                        <a-button type="primary" class="margin-r" @click="search">
                                            查询
                                        </a-button>
                                    </a-form-item>
                                </a-form>
                            </a-form-item>
                        </a-form>
                    </a-col>
                    <a-col :span="4" class="textal_r">
                        <a-button type="primary" @click="addRow()">创建活动</a-button>
                    </a-col>
                </a-row>
                <a-table :columns="columns" bordered
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         style=" min-height: calc(100vh - 234px);min-width: max-content"
                         class="yj-table-img">
                    <span slot="action" slot-scope="text, record">
                        <a @click="Detail(record)">{{ record.PubState==0?'活动编辑':'活动详情' }}</a>
                        <a-divider type="vertical" />
                        <a @click="H5(record)">{{ record.PubState==0?'素材编辑':'素材详情' }}</a>
                        <a-divider type="vertical" />
                        <a @click="Product(record)">{{ record.PubState==0?'商品编辑':'商品详情' }}</a>
                        <a-divider type="vertical" />
                        <a @click="PrizeList(record)">{{ record.PubState==0?'奖励编辑':'奖励详情' }}</a>
                        <a-divider type="vertical" />
                        <a @click="UpdateOnline(record)" v-if="record.PubState!=2">{{ record.PubState==0?'活动发布':'活动结束' }}</a>
                    </span>
                    <span slot="InfoReport" slot-scope="text, record">
                        阅读<a @click="M2ActivityList(record)" style="cursor:pointer;margin-left:4px;">{{record.InfoReport.ReadCount }}</a><br />
                        拉新<a @click="NewM2ActivityList(record)" style="cursor:pointer;margin-left:4px;">{{record.InfoReport.NewUserCount }}</a><br />
                        手机<a @click="PhoneList(record)" style="cursor:pointer;margin-left:4px;">{{record.InfoReport.PhoneCount }}</a>
                    </span>
                    <span slot="AwardPool" slot-scope="text, record">
                        <a @click="PrizeSendDetail(record)" style="cursor:pointer;">{{record.InfoReport.AwardPoolCount }}</a>/<a @click="PrizeList(record)" style="cursor:pointer;">{{record.InfoReport.AwardPoolTotal }}</a>
                    </span>
                    <span slot="InfoAward" slot-scope="text, record">
                        <a @click="InfoReport(record)" style="cursor:pointer;">￥{{record.InfoAward.HasReadMoney + record.InfoAward.HasShareMone}}</a>/<a @click="Detail(record)" style="cursor:pointer;">￥{{record.AwardLuckMoney.BudgetMoney }}</a>
                    </span>
                </a-table>
            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "M2Activity_List",
        data() {
            return {
                columns: [
                    {
                        title: "名称",
                        dataIndex: "Name",
                        align: "center",
                        width: 230
                    },
                    {
                        title: "开始时间",
                        align: "center",
                        width: 150,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.BeginTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "结束时间",
                        align: "center",
                        width: 150,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.EndTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "发布状态",
                        align: "center",
                        width: 90,
                        customRender: (text, item) => {
                            var PubState = '未发布';
                            if (item.PubState == 1) {
                                PubState = '已发布';
                            }
                            if (item.PubState == 2) {
                                PubState = '已结束';
                            }
                            return PubState
                        }
                    },
                    {
                        title: "关注量",
                        width: 90,
                        align: "center",
                        scopedSlots: { customRender: 'InfoReport' }
                    },
                    {
                        title: "奖品",
                        width: 70,
                        align: "center",
                        scopedSlots: { customRender: 'AwardPool' }
                    },
                    {
                        title: "红包",
                        width: 80,
                        align: "center",
                        scopedSlots: { customRender: 'InfoAward' }
                    },
                    {
                        title: '操作',
                        align: "center",
                        width: 390,
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                tableData: [],
                name: "",
                PubState: "-1",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/M2Activity/GetM2ActivityInfoList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name,
                        PubState: self.PubState
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            Detail: function (data) {
                var self = this;
                var disabled = data.PubState == 0 ? 0 : 1;
                self.$router.push({
                    name: "M2Activity_Detail",
                    query: { ID: data.ID, disabled: disabled }
                })
            },
            H5: function (data) {
                var self = this;
                var disabled = data.PubState == 0 ? 0 : 1;
                var MaterialID = 0;
                if (data.M2ActivityCarrier.length > 0) {
                    MaterialID = data.M2ActivityCarrier[0].MaterialID;
                }
                self.$router.push({
                    name: "NewMaterial_H5",
                    query: { ActivityID: data.ID, disabled: disabled, MaterialID: MaterialID }
                })
            },
            Product: function (data) {
                var self = this;
                var disabled = data.PubState == 0 ? 0 : 1;
                self.$router.push({
                    name: "M2Activity_ProductList",
                    query: { ID: data.ID, disabled: disabled }
                })
            },
            PrizeList: function (data) {
                var self = this;
                var disabled = data.PubState == 0 ? 0 : 1;
                self.$router.push({
                    name: "M2Activity_PrizeList",
                    query: { ActivityID: data.ID, disabled: disabled }
                })
            },
            addRow() {
                var self = this;
                self.$router.push({
                    name: "M2Activity_Detail",
                    query: { ID: 0 }
                });
            },
            UpdateOnline(e) {
                var self = this;
                var con = e.PubState == 0 ? '发布' : '结束';
                var PubState = e.PubState == 0 ? 1 : 2;
                this.$confirm({
                    title: '提示',
                    content: '是否' + con + e.Name,
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/MarketingModule/M2Activity/UpdateM2ActivityInfoState",
                            data: {
                                id: e.ID,
                                PubState: PubState
                            },
                            OnSuccess: function () {
                                self.pagination.current = 1;
                                self.getTableData();
                                self.$message.success(con + e.Name + "成功", 1.5, function () { });
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            //阅读明细
            M2ActivityList(e) {
                var self = this;
                self.$router.push({
                    name: "M2Activity_ReadList",
                    query: { id: e.ID, IsNewUser: 0 }
                })
            },
            //拉新明细
            NewM2ActivityList(e) {
                var self = this;
                self.$router.push({
                    name: "M2Activity_ReadList",
                    query: { id: e.ID, IsNewUser: 1 }
                })
            },
            //活手机明细
            PhoneList(e) {
                var self = this;
                self.$router.push({
                    name: "M2Activity_PhoneList",
                    query: { id: e.ID }
                })
            },
            //活动领奖明细
            PrizeSendDetail(e) {
                var self = this;
                self.$router.push({
                    name: "M2Activity_PrizeSendDetail",
                    query: { id: e.ID }
                })
            },
            InfoReport: function (data) {
                var self = this;
                self.$router.push({
                    name: "M2Activity_ActivityAwardDetail", query: {
                        ID: data.ID
                    }
                })
            },
        },

        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>